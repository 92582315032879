
import Vue from 'vue'
export default Vue.extend({
  props: {
    error: {
      type: String,
      required: false,
      default: () => null
    }
  }
})
